import { Loading } from 'quasar'
export default ({ router, store }) => {
  router.beforeEach((to, from, next) => {
    if (
      to.fullPath !== from.fullPath ||
      to.name !== from.name ||
      to.params !== from.params ||
      to.params.name !== from.params.name ||
      to.path !== from.path
    ) {
      Loading.show()
      next()
    }
  })

  router.afterEach(async to => {
    await store.dispatch('metatags/currentRoute', to.path)
    if (!process.env.SERVER) {
      setTimeout(function() {
        window.scrollTo(0, 0)
      }, 700)
    }
    Loading.hide()
  })
}
