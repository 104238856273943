export default {
  path: '/autos',
  component: () => import('layouts/autos-layout.vue'),
  children: [
    /* {
      path: '/',
      name: 'autos-home',
      component: () => import('pages/autos/index.vue')
    }, */
    /* {
      path: 'catalogo',
      name: 'catalogue',
      component: () => import('pages/autos/catalogue.vue')
    }, */
    /* {
      path: 'modelos',
      name: 'vehicles',
      component: () => import('pages/autos/modelos/index.vue')
    }, */
    /* {
      path: 'prueba-de-manejo',
      name: 'prueba-de-manejo',
      component: () => import('pages/autos/modelos/index.vue')
    }, */
    /* {
      path: 'cotizador-de-autos',
      name: 'cotizador-de-autos',
      component: () => import('pages/autos/modelos/index.vue')
    }, */
    /* {
      path: 'promociones',
      component: () => import('layouts/promotions-layout.vue'),
      children: [
        {
          path: '',
          name: 'promotions',
          component: () => import('pages/autos/promocion/index.vue')
        },
        {
          path: 'boostersale',
          name: 'boostersale',
          component: () => import('pages/autos/promocion/boostersale.vue')
        },
        {
          path: 'adios-a-los-2023',
          name: 'adios-a-los-2023',
          component: () => import('pages/autos/promocion/adios-a-los-2023.vue')
        },
        {
          path: ':slug',
          name: 'promotions-car',
          component: () => import('pages/autos/promocion/index.vue')
        }
      ]
    }, */
    /* {
      path: 'promociones/:id',
      name: 'detail-promotion',
      component: () => import('pages/autos/promocion/detail-promotion.vue')
    }, */
    /* {
      name: 'dealer',
      path: 'concesionarias',
      component: () => import('pages/autos/concesionarias/index.vue')
    }, */
    /* {
      name: 'garantia',
      path: 'garantia/:type',
      component: () => import('pages/autos/garantia/index.vue')
    }, */
    /* {
      path: 'legal',
      name: 'legal',
      component: () => import('pages/autos/legal.vue')
    }, */
    /* {
      path: 'tecnologia',
      name: 'technology',
      component: () => import('pages/autos/tecnologia.vue')
    }, */
    {
      path: 'contacto',
      name: 'contacto',
      component: () => import('pages/autos/contacto/index.vue')
    },
    {
      path: 'comentario',
      name: 'comentario',
      component: () => import('pages/autos/contacto/index.vue')
    },
    {
      path: 'servicio',
      name: 'servicio',
      component: () => import('pages/autos/servicio/index.vue')
    },
    /* {
      path: 'airlife',
      name: 'airlife',
      component: () => import('pages/autos/airlife/airlife-page.vue')
    }, */
    {
      path: 'campana-de-servicio',
      name: 'campana-de-servicio',
      component: () => import('pages/autos/service-suzuki.vue')
    },
    {
      path: 'costos-de-mantenimiento',
      name: 'costos-de-mantenimiento',
      component: () => import('pages/autos/maintenance-costs.vue')
    },
    /* {
      path: 'proteccion-suzuki',
      name: 'proteccion-suzuki',
      component: () => import('pages/autos/proteccion/index.vue')
    }, */
    {
      path: 'asistencia-computadoras-arneses',
      name: 'comunicado',
      component: () => import('pages/autos/comunicado.vue')
    }
    /* {
      path: 'error-404',
      name: 'error-404',
      component: () => import('pages/autos/error-404.vue')
    },
    {
      path: ':name/:type?',
      name: 'autos',
      component: () => import('pages/autos/detalle.vue')
    } */
  ]
}
