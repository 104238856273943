import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

export default async ({ Vue }) => {
  Sentry.init({
    dsn: 'https://c3df5aba117041dd9110502e7864a4ed@sentry.io/2448909',
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    environment: process.env.DEV ? 'development' : 'production',
    release: 'suzuki-website@1.1.0'
  })
}
