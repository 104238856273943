import main from './routes/main'
import autos from './routes/autos'
// import cotizador from './routes/cotizador'

const routes = [main, autos]

// if (process.env.DEV) {
//   routes.push(() => import('./routes/test'))
// }

// Always leave this as last one
// if (process.env.MODE !== 'ssr') {
/* routes.push({
  path: '*',
  redirect: { name: 'error-404' }
  // component: () => import('pages/autos/error-404.vue')
}) */
// }

export default routes
