export default {
  path: '/',
  component: () => import('layouts/main-layout.vue'),
  children: [
    {
      path: '',
      name: 'main-home',
      component: () => import('pages/index.vue')
    }
  ]
}
