import { axiosInstance } from 'boot/axios.js'

export async function query(ctx) {
  try {
    const response = await axiosInstance({
      url: '/meta-tags?limit=10000',
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
  } catch (e) {
    return e
  }
}

export function currentRoute(ctx, path) {
  ctx.commit('currentRoute', path)
}
