export function pushAccessory({ commit }, data) {
  commit('pushAccessory', data)
}

export function removeAccessory({ commit }, data) {
  commit('removeAccessory', data)
}

export function accessories({ commit }, data) {
  commit('accessories', data)
}
