import { axiosInstance } from 'boot/axios.js'

export async function save(ctx, data = {}) {
  try {
    const response = await axiosInstance({
      url: 'promotion-clients',
      data: data,
      method: 'POST'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}
