/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import 'quasar/dist/quasar.ie.polyfills.js'



import '@quasar/extras/eva-icons/eva-icons.css'




// We load Quasar stylesheet file
import 'quasar/dist/quasar.sass'


// We add Quasar addons, if they were requested
import 'quasar/src/css/flex-addon.sass'



import 'src/css/app.scss'


import Vue from 'vue'
import createApp from './app.js'




import qboot_Bootaxios from 'boot/axios'

import qboot_Bootnotify from 'boot/notify'

import qboot_Bootfilters from 'boot/filters'

import qboot_Bootcookies from 'boot/cookies'

import qboot_Bootrouter from 'boot/router'

import qboot_Bootaddressbarcolor from 'boot/addressbar-color'

import qboot_Bootgoogleanalytics from 'boot/google-analytics'

import qboot_Bootpolifylls from 'boot/polifylls'

import qboot_Bootpolyfillentries from 'boot/polyfill-entries'

import qboot_Bootgooglemaps from 'boot/google-maps'

import qboot_Bootaos from 'boot/aos'

import qboot_Bootsentry from 'boot/sentry'



import { addPreFetchHooks } from './client-prefetch.js'










async function start () {
  const { app, store, router } = await createApp()

  

  
  let routeUnchanged = true
  const redirect = url => {
    routeUnchanged = false
    window.location.href = url
  }

  const urlPath = window.location.href.replace(window.location.origin, '')
  const bootFiles = [qboot_Bootaxios,qboot_Bootnotify,qboot_Bootfilters,qboot_Bootcookies,qboot_Bootrouter,qboot_Bootaddressbarcolor,qboot_Bootgoogleanalytics,qboot_Bootpolifylls,qboot_Bootpolyfillentries,qboot_Bootgooglemaps,qboot_Bootaos,qboot_Bootsentry]

  for (let i = 0; routeUnchanged === true && i < bootFiles.length; i++) {
    if (typeof bootFiles[i] !== 'function') {
      continue
    }

    try {
      await bootFiles[i]({
        app,
        router,
        store,
        Vue,
        ssrContext: null,
        redirect,
        urlPath
      })
    }
    catch (err) {
      if (err && err.url) {
        window.location.href = err.url
        return
      }

      console.error('[Quasar] boot error:', err)
      return
    }
  }

  if (routeUnchanged === false) {
    return
  }
  

  

    
    addPreFetchHooks(router, store)
    

    

    new Vue(app)

    

  

}

start()
