export function items(state, data) {
  state.items = data
}

export function currentRoute(state, data) {
  state.currentRoute = data
  let currentMeta = state.items.find(x => {
    return (
      x.route === state.currentRoute || x.route === state.currentRoute + '/'
    )
  })

  if (currentMeta) {
    state.current = {
      ...state.default,
      ...{
        title: currentMeta.title,
        meta: {
          description: {
            name: 'description',
            content: currentMeta.description
          },
          'og:title': {
            name: 'og:title',
            content: currentMeta.title
          },
          'og:description': {
            name: 'og:description',
            content: currentMeta.description
          },
          'og:url': {
            name: 'og:url',
            content: 'https://www.suzuki.com.mx' + state.currentRoute
          },
          'og:locale': {
            name: 'og:local',
            content: 'es_MX'
          },
          'og:image': {
            name: 'og:image',
            content: currentMeta.image
          },
          'twitter:card': {
            name: 'twitter:card',
            content: 'summary'
          },
          'twitter:site': {
            name: 'twitter:site',
            content: '@suzuki_mex'
          }
        }
      }
    }
  } else {
    state.current = state.default
  }
}
