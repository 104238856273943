export function items(state) {
  return state.items
}

export function current(state) {
  return state.current
}

export function currentRoute(state) {
  return state.currentRoute
}
