export function items(state, data) {
  state.items = data
}

export function pagination(state, data) {
  state.pagination = data
}

export function item(state, data) {
  let versions = data.versions.filter(
    version => version.status === 'PUBLISHED' || version.status === 'SOLD-OUT'
  )
  for (let version of versions) {
    let transmissions = version.transmissions.filter(
      transmission =>
        (transmission.status && transmission.status) === 'PUBLISHED' ||
        (transmission.status && transmission.status === 'SOLD-OUT')
    )
    let colors = version.colors.filter(
      color =>
        (color.status && color.status === 'PUBLISHED') ||
        (color.status && color.status === 'SOLD-OUT')
    )
    version.transmissions = transmissions
    version.colors = colors
  }
  data.versions = versions
  state.item = data
}

export function modules(state, data) {
  state.modules = data
}

export function all(state, data) {
  state.all = data
}

export function itemsForm(state, data) {
  state.itemsForm = data
}
