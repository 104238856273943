const errors = {
  500: {
    DEFAULT:
      'Sucedio un error desconocido al realizar la petición al servidor. Por favor contacte a soporte.'
  },
  401: {
    DEFAULT: 'Debe estar autenticado para poder acceder a este recurso'
  },
  404: {
    DEFAULT: 'El recurso no fue encontrado'
  },
  442: {
    DEFAULT: 'Existen errores de validación en la forma'
  },
  422: {
    DEFAULT: 'Existen errores de validación en la forma'
  },
  429: {
    DEFAULT:
      'Ha excedido el numero de intentos, por favor espere un minuto e intente nuevamente.'
  },
  200: {
    DEFAULT: 'Operación realizada'
  }
}

const getError = (status, code = 'DEFAULT') => {
  const _status = errors[status] ? status : 500
  const _code = errors[_status][code] ? code : 'DEFAULT'
  return errors[_status][_code]
}

export default getError
