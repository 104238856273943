export default () => ({
  items: [],
  currentRoute: {},
  current: {},
  default: {
    title: "SUZUKI Autos México | Autos y SUV's",
    meta: {
      description: {
        name: 'description',
        content:
          'Bienvenido a SUZUKI Autos México: autos compactos, familiares y turbo con imaginación, creatividad, avanzada tecnología y respeto por el medio ambiente.'
      },
      'og:title': {
        name: 'og:title',
        content: "SUZUKI Autos México | Autos y SUV's"
      },
      'og:description': {
        name: 'og:description',
        content:
          'Bienvenido a SUZUKI Autos México: autos compactos, familiares y turbo con imaginación, creatividad, avanzada tecnología y respeto por el medio ambiente.'
      },
      'og:locale': {
        name: 'og:local',
        content: 'es_MX'
      },
      'twitter:card': {
        name: 'twitter:card',
        content: 'summary'
      },
      'twitter:site': {
        name: 'twitter:site',
        content: '@suzuki_mex'
      }
    }
  }
})
