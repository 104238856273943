import { axiosInstance } from 'boot/axios.js'

export async function save(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: 'contacts',
      data: queryParams,
      method: 'POST'
    })
    return response
  } catch (e) {
    return e
  }
}
