import { axiosInstance } from 'boot/axios.js'

export async function replacement(ctx, data = {}) {
  try {
    const response = await axiosInstance({
      url: '/forms/assistance',
      data: data,
      method: 'POST'
    })
    return response
  } catch (e) {
    throw e
  }
}

export async function niv(ctx, data = {}) {
  try {
    const response = await axiosInstance({
      url: '/forms/assistance/niv/' + data.niv,
      method: 'GET'
    })
    return response
  } catch (e) {
    throw e
  }
}
