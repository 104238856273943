export function setModel(state, model) {
  state.quote.model = model
}

export function setDeposit(state, deposit) {
  state.quote.deposit = deposit
}

export function setDepositPercentage(state, depositPercentage) {
  state.quote.depositPercentage = depositPercentage
}

export function setMonthly(state, monthly) {
  state.quote.monthly = monthly
}

export function setPeriod(state, period) {
  state.quote.period = period
}
