export function setVersion(state, version) {
  state.customize.version = version
}

export function setTransmission(state, transmission) {
  state.customize.transmission = transmission
}

export function setColor(state, color) {
  state.customize.color = color
}

export function setPrice(state, year) {
  state.customize.price = year
}

export function setYear(state, year) {
  state.customize.year = year
}
