export function setVersion({ commit }, version) {
  commit('setVersion', version)
}

export function setTransmission({ commit }, transmission) {
  commit('setTransmission', transmission)
}

export function setColor({ commit }, color) {
  commit('setColor', color)
}

export function setPrice({ commit }, color) {
  commit('setPrice', color)
}

export function setYear({ commit }, color) {
  commit('setYear', color)
}
