//
//
//
//
//
//

export default {
  name: 'app',
  meta() {
    return {
      ...this.metatags
    }
  },
  computed: {
    metatags() {
      return this.$store.getters['metatags/current']
    }
  },
  async preFetch({ store, currentRoute }) {
    try {
      await store.dispatch('metatags/query')
      await store.dispatch('metatags/currentRoute', currentRoute.path)
    } catch (e) {
      throw e
    }
  }
}
