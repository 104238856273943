export function pushAccessory(state, data) {
  state.accessories.push(data)
}

export function removeAccessory(state, data) {
  let i = state.accessories.indexOf(data)
  if (i > -1) {
    state.accessories.splice(i, 1)
  }
}

export function accessories(state, data) {
  state.accessories = data
}
