import { axiosInstance } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: '/app-configurations/current',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
  } catch (e) {
    return e
  }
}

export async function datetime(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: '/app-configurations/datetime',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('datetime', response.data)
  } catch (e) {
    return e
  }
}

export function acceptImportantAnnouncement(ctx) {
  ctx.commit('acceptImportantAnnouncement', true)
}
