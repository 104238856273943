export default () => ({
  current: {
    name: 'SWIFT',
    price: 242990,
    versions: [{ id: 1, name: 'GLX' }],
    carImage: {
      url: 'https://cdn.marciano.space/suzuki/cars/swift.png'
    },
    transmissions: 'TM'
  }
})
