import * as VueGoogleMaps from 'vue2-google-maps'

export default ({ Vue }) => {
  if (!process.env.SERVER) {
    Vue.use(VueGoogleMaps, {
      load: {
        // key: 'AIzaSyCPrKkP7tNlvHsz2_yumI8gdCfZ9mdV8Y0',
        key: 'AIzaSyCPrKkP7tNlvHsz2_yumI8gdCfZ9mdV8Y0',
        libraries: 'places'
      },
      installComponents: true
    })
  }
}
