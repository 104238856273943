import AOS from 'aos'
import 'aos/dist/aos.css'

export default ({ Vue }) => {
  const aosPlugin = {
    install: () => {
      AOS.init({
        // add your settings here
      })
    }
  }

  setTimeout(() => {
    Vue.use(aosPlugin)
  }, 1000)
}
