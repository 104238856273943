export function item(state, data) {
  state.item = data
}

export function datetime(state, data) {
  state.datetime = data
}

export function acceptImportantAnnouncement(state, data) {
  state.acceptImportantAnnouncement = data
}
