/* eslint-disable no-undef */
export default {
  logEvent(category, action, label, sessionId = null) {
    if (!process.env.SERVER) {
      dataLayer.push({
        appEventCategory: category,
        appEventAction: action,
        appEventLabel: label,
        sessionId: sessionId
      })
      dataLayer.push({ event: 'appEvent' })
    }
  },
  logPage(path, name, sessionId = null) {
    if (!process.env.SERVER) {
      dataLayer.push({
        screenPath: path,
        screenName: name,
        sessionId: sessionId
      })
      dataLayer.push({ event: 'appScreenView' })
    }
  },

  /**
   * Documentación de código basado en el manual de etiquetado de Suzuki
   * 1.1 Prueba de Manejo
   */
  /**
   * 1.2 Click
   * El siguiente código se debe de ejecutar cuando el usuario de click en el botón “Prueba de Manejo”.
   * @param {string} brand Nombre del vehículo de interés
   * @param {string} version Versión del vehículo de interés
   * @param {string} price Costo del vehículo de interés
   */
  logEventPruebaManejoClick(brand, version, price) {
    if (!process.env.SERVER) {
      dataLayer.push({
        event: 'evPruebaManejo',
        brand,
        version,
        price: price.toString(),
        action: 'Click'
      })
    }
  },
  /**
   * 1.3 Intento de envío de datos
   * El siguiente código se debe de ejecutar cuando el usuario de click en el botón “Enviar Datos”.
   * @param {string} concesionaria Nombre de la concesionaria elegida
   * @param {string} brand Nombre del vehículo de interés
   * @param {string} version Versión del vehículo de interés
   * @param {string} price Costo del vehículo de interés
   */
  logEventPruebaManejoIntento(concesionaria, brand, version, price) {
    if (!process.env.SERVER) {
      dataLayer.push({
        event: 'evPruebaManejo',
        concesionaria,
        brand,
        version,
        price: price.toString(),
        action: 'Intento'
      })
    }
  },
  /**
   * 1.4 Éxito de envío de datos
   * El siguiente código se debe de ejecutar cuando el usuario realizó el envío de datos con éxito.
   * @param {string} concesionaria Nombre de la concesionaria elegida
   * @param {string} brand Nombre del vehículo de interés
   * @param {string} version Versión del vehículo de interés
   * @param {string} price Costo del vehículo de interés
   */
  logEventPruebaManejoExito(concesionaria, brand, version, price) {
    if (!process.env.SERVER) {
      dataLayer.push({
        event: 'evPruebaManejo',
        concesionaria,
        brand,
        version,
        price: price.toString(),
        action: 'Exito'
      })
    }
  },
  /**
   * 1.5 Error de envío de datos
   * El siguiente código se debe de ejecutar cuando el usuario realizó el envío de datos con error.
   * @param {string} concesionaria Nombre de la concesionaria elegida
   * @param {string} brand Nombre del vehículo de interés
   * @param {string} version Versión del vehículo de interés
   * @param {string} price Costo del vehículo de interés
   * @param {string} error Nombre del campo o mensaje de error que se generó
   */
  logEventPruebaManejoError(concesionaria, brand, version, price, error) {
    if (!process.env.SERVER) {
      dataLayer.push({
        event: 'evPruebaManejo',
        concesionaria,
        brand,
        version,
        price: price.toString(),
        error,
        action: 'Error'
      })
    }
  },
  /**
   * 1.6 Cotización
   */
  /**
   * 1.7 Cotización Click
   * El siguiente código se debe de ejecutar cuando el usuario de click sobre alguno de los botones “Cotizalo” o “Elige tu {{Coche}}”
   * @param {string} version Versión del vehículo de interés
   * @param {string} brand Nombre del vehículo de interés
   */
  logEventCotizacionClick(version, brand) {
    if (!process.env.SERVER) {
      dataLayer.push({
        event: 'evCotizacion',
        version,
        brand,
        action: 'Intento Click'
      })
    }
  },
  /**
   * 1.8 Cotización resumen
   * El siguiente código se debe de ejecutar cuando el usuario llegue a la ventana de resumen y esta sea cargado exitosamente.
   * @param {string} version Versión del vehículo de interés
   * @param {string} color Color del coche
   * @param {string} transmision Transmisión del coche
   * @param {string} price Precio total del coche
   * @param {string} brand Nombre del coche
   */
  logEventCotizacionResumen(version, color, transmision, price, brand) {
    if (!process.env.SERVER) {
      dataLayer.push({
        event: 'evCotizacion',
        version,
        color,
        transmision,
        price: price.toString(),
        brand,
        action: 'Resumen'
      })
    }
  },
  /**
   * 1.9 Cotización “Cotizalo”
   * El siguiente código se debe de ejecutar cuando el usuario de click en el botón “Cotizalo”.
   * @param {string} version Versión del vehículo de interés
   * @param {string} color Color del coche
   * @param {string} transmision Transmisión del coche
   * @param {string} price Precio total del coche
   * @param {string} brand Nombre del coche
   */
  logEventCotizacionCotizalo(version, color, transmision, price, brand) {
    if (!process.env.SERVER) {
      dataLayer.push({
        event: 'evCotizacion',
        version,
        color,
        transmision,
        price: price.toString(),
        brand,
        action: 'Cotizalo'
      })
    }
  },
  /**
   * 1.10 Cotización más información
   * El siguiente código se debe de ejecutar cuando el usuario de click sobre el botón “Más Información”.
   * @param {string} version Versión del vehículo de interés
   * @param {string} color Color del coche
   * @param {string} transmision Transmisión del coche
   * @param {string} price Precio total del coche
   * @param {string} brand Nombre del coche
   */
  logEventCotizacionMasInformacion(version, color, transmision, price, brand) {
    if (!process.env.SERVER) {
      dataLayer.push({
        event: 'evCotizacion',
        version,
        color,
        transmision,
        price: price.toString(),
        brand,
        action: 'Mas Informacion'
      })
    }
  },
  /**
   * 1.11 Cotización Descargar ficha
   * El siguiente código se debe de ejecutar cuando el usuario de click sobre el botón “Descarga Ficha”.
   * @param {string} version Versión del vehículo de interés
   * @param {string} color Color del coche
   * @param {string} transmision Transmisión del coche
   * @param {string} price Precio total del coche
   * @param {string} brand Nombre del coche
   */
  logEventCotizacionDescargaFicha(version, color, transmision, price, brand) {
    if (!process.env.SERVER) {
      dataLayer.push({
        event: 'evCotizacion',
        version,
        color,
        transmision,
        price: price.toString(),
        brand,
        action: 'Descarga Ficha'
      })
    }
  },
  /**
   * 1.12 Cotización Me interesa
   * El siguiente código se debe de ejecutar cuando el usuario de click sobre el botón “Me interesa”.
   * @param {string} version Versión del vehículo de interés
   * @param {string} color Color del coche
   * @param {string} transmision Transmisión del coche
   * @param {string} price Precio total del coche
   * @param {string} brand Nombre del coche
   * @param {string} porcentajeEnganche Porcentaje de Enganche
   * @param {string} meses Plazo de meses
   * @param {string} seguroAuto Seguro de Auto. De contado o Financiado
   */
  logEventCotizacionMeInteresa(
    version,
    color,
    transmision,
    price,
    brand,
    porcentajeEnganche,
    meses,
    seguroAuto
  ) {
    if (!process.env.SERVER) {
      dataLayer.push({
        event: 'evCotizacion',
        version,
        color,
        transmision,
        price: price.toString(),
        brand,
        porcentajeEnganche,
        meses,
        seguroAuto,
        action: 'Me interesa'
      })
    }
  },
  /**
   * 1.13 Cotización “Descarga cotización”
   * El siguiente código se debe de ejecutar cuando el usuario de click sobre el botón “Descarga Cotización”.
   * @param {string} version Versión del vehículo de interés
   * @param {string} color Color del coche
   * @param {string} transmision Transmisión del coche
   * @param {string} price Precio total del coche
   * @param {string} brand Nombre del coche
   * @param {string} porcentajeEnganche Porcentaje de Enganche
   * @param {string} meses Plazo de meses
   * @param {string} seguroAuto Seguro de Auto. De contado o Financiado
   */
  logEventCotizacionDescargaCotizacion(
    version,
    color,
    transmision,
    price,
    brand,
    porcentajeEnganche,
    meses,
    seguroAuto
  ) {
    if (!process.env.SERVER) {
      dataLayer.push({
        event: 'evCotizacion',
        version,
        color,
        transmision,
        price: price.toString(),
        brand,
        porcentajeEnganche,
        meses,
        seguroAuto,
        action: 'Descarga Cotización'
      })
    }
  },
  /**
   * 1.14 Cotización Enviar Datos Intento
   * El siguiente código se debe de ejecutar cuando el usuario de click sobre el botón “Enviar Datos”.
   * @param {string} version Versión del vehículo de interés
   * @param {string} color Color del coche
   * @param {string} transmision Transmisión del coche
   * @param {string} price Precio total del coche
   * @param {string} brand Nombre del coche
   * @param {string} porcentajeEnganche Porcentaje de Enganche
   * @param {string} meses Plazo de meses
   * @param {string} seguroAuto Seguro de Auto. De contado o Financiado
   */
  logEventCotizacionEnviarDatosIntento(
    version,
    color,
    transmision,
    price,
    brand,
    porcentajeEnganche,
    meses,
    seguroAuto
  ) {
    if (!process.env.SERVER) {
      dataLayer.push({
        event: 'evCotizacion',
        version,
        color,
        transmision,
        price: price.toString(),
        brand,
        porcentajeEnganche,
        meses,
        seguroAuto,
        action: 'Intento'
      })
    }
  },
  /**
   * 1.15 Cotización Enviar Datos éxito
   * El siguiente código se debe de ejecutar cuando el usuario genera exitosamente el enviado de formulario.
   * @param {string} version Versión del vehículo de interés
   * @param {string} color Color del coche
   * @param {string} transmision Transmisión del coche
   * @param {string} price Precio total del coche
   * @param {string} brand Nombre del coche
   * @param {string} porcentajeEnganche Porcentaje de Enganche
   * @param {string} meses Plazo de meses
   * @param {string} seguroAuto Seguro de Auto. De contado o Financiado
   */
  logEventCotizacionDatosExito(
    version,
    color,
    transmision,
    price,
    brand,
    porcentajeEnganche,
    meses,
    seguroAuto
  ) {
    if (!process.env.SERVER) {
      dataLayer.push({
        event: 'evCotizacion',
        version,
        color,
        transmision,
        price: price.toString(),
        brand,
        porcentajeEnganche,
        meses,
        seguroAuto,
        action: 'Exito'
      })
    }
  },
  /**
   * 1.16 Cotización Enviar Datos error
   * El siguiente código se debe de ejecutar cuando el usuario genera un error al tratar de enviar la información.
   * @param {string} version Versión del vehículo de interés
   * @param {string} color Color del coche
   * @param {string} transmision Transmisión del coche
   * @param {string} price Precio total del coche
   * @param {string} brand Nombre del coche
   * @param {string} porcentajeEnganche Porcentaje de Enganche
   * @param {string} meses Plazo de meses
   * @param {string} seguroAuto Seguro de Auto. De contado o Financiado
   */
  logEventCotizacionDatosError(
    version,
    color,
    transmision,
    price,
    brand,
    porcentajeEnganche,
    meses,
    seguroAuto,
    error
  ) {
    if (!process.env.SERVER) {
      dataLayer.push({
        event: 'evCotizacion',
        version,
        color,
        transmision,
        price: price.toString(),
        brand,
        porcentajeEnganche,
        meses,
        seguroAuto,
        error,
        action: 'Error'
      })
    }
  }
}
