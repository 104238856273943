import { axiosInstance } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: '/car-models',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
    return response
  } catch (e) {
    return e
  }
}

export async function get(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: parseUrl('car-models', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}

export async function modules(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: parseUrl('modules', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('modules', response.data.items)
  } catch (e) {
    return e
  }
}

export async function all(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: '/car-models/all',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('all', response.data.items)
    ctx.commit('pagination', response.data.pagination)
  } catch (e) {
    return e
  }
}

export async function queryForm(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: '/car-models',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('itemsForm', response.data.items)
    return response
  } catch (e) {
    return e
  }
}

function parseUrl(state, params) {
  if (state && params.name) {
    return `/${state}/${params.name}`
  }
  return `/${state}`
}
