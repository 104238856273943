import { axiosInstance } from 'boot/axios.js'

export async function get(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: `/prospect/${queryParams.code}`,
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
    return response
  } catch (e) {
    return e
  }
}
