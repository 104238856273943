export function items(state) {
  return state.items
}

export function item(state) {
  return state.item
}

export function pagination(state) {
  return state.pagination
}

export function modules(state) {
  return state.modules
}

export function all(state) {
  return state.all
}

export function itemsForm(state) {
  return state.itemsForm
}
