export function setModel({ commit }, model) {
  commit('setModel', model)
}

export function setDeposit({ commit }, deposit) {
  commit('setDeposit', deposit)
}

export function setDepositPercentage({ commit }, depositPercentage) {
  commit('setDepositPercentage', depositPercentage)
}

export function setMonthly({ commit }, monthly) {
  commit('setMonthly', monthly)
}

export function setPeriod({ commit }, period) {
  commit('setPeriod', period)
}
