import { axiosInstance } from 'boot/axios.js'

export async function query(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: '/versions',
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('items', response.data.items)
    ctx.commit('pagination', response.data.pagination)
  } catch (e) {
    return e
  }
}

export async function get(ctx, queryParams = {}) {
  try {
    const response = await axiosInstance({
      url: parseUrl('versions', queryParams),
      params: queryParams,
      method: 'GET'
    })
    ctx.commit('item', response.data)
  } catch (e) {
    return e
  }
}

function parseUrl(state, params) {
  if (state && params.id) {
    return '/' + state + '/' + params.id
  }
  return '/' + state
}
